import React from "react";
import PropTypes from "prop-types";

/**
 * Note that the `noText` version doesn't change the bounding box!
 */

const Logo = ({ color, noText, alt }) => (
  <svg width={194} height={65} fill="none" viewBox="0 0 194 65" role="img">
    <title>{alt || "Alserkal"}</title>
    <g fill={color || "#fff"} clipPath="url(#logoclippath)">
      <path d="M3.44 0H0v51.276h3.44V0zm27.208 12.82h-3.44v51.277h3.44V12.821zM76 0h-3.44v51.276H76V0zM12.509 0h-3.44v51.276h3.44V0zm27.208 25.636h-3.439v25.636h3.44V25.636zm18.144 0h-3.44v25.636h3.44V25.636z" />
      <path d="M30.652 3.315V0H18.14v51.276h3.44V3.315h9.073zm18.135 22.321h-3.44v25.636h3.44V25.636zM66.93 0h-3.439v51.276h3.44V0z" />
    </g>
    {noText ? null : (
      <g>
        <path
          fill={color || "#fff"}
          d="M90 43.774c0 4.285 3.117 7.791 7.222 7.791 2.158 0 3.956-1.109 4.795-2.307v2.007h3.296V36.282h-3.296v2.008c-.839-1.2-2.637-2.308-4.795-2.308-4.105 0-7.222 3.506-7.222 7.792zm12.287 0c0 2.697-1.798 4.794-4.466 4.794-2.667 0-4.465-2.097-4.465-4.794 0-2.697 1.798-4.795 4.466-4.795 2.667 0 4.465 2.098 4.465 4.795zM109.18 51.265h3.14V26h-3.14v25.265zM127.454 47.13c0-2.278-1.288-3.836-3.985-4.525l-2.518-.66c-1.258-.33-2.008-.839-2.008-1.797 0-.96.929-1.529 2.188-1.529 1.169 0 2.158.6 2.457 1.858h3.297c-.24-2.697-2.487-4.495-5.724-4.495-3.356 0-5.574 1.678-5.574 4.196 0 2.187 1.259 3.865 3.836 4.525l2.457.659c1.559.39 2.218.959 2.218 1.978 0 1.019-.989 1.588-2.427 1.588-1.469 0-2.817-.6-3.087-2.218h-3.416c.179 2.997 2.906 4.855 6.413 4.855 3.356 0 5.873-1.528 5.873-4.435zM144.158 46.56h-3.446c-.69 1.439-2.128 2.098-3.776 2.098-2.667 0-4.405-1.798-4.525-4.075h11.927c.299-5.095-2.907-8.6-7.492-8.6-4.615 0-7.702 3.355-7.702 7.79 0 4.496 3.207 7.792 7.672 7.792 3.806 0 6.503-2.188 7.342-5.004zm-7.402-7.82c1.828 0 3.536 1.078 4.046 3.505h-8.271c.449-2.307 2.157-3.506 4.225-3.506zM147.123 51.265h3.297v-7.312c0-2.996 1.708-4.585 3.596-4.585.599 0 1.079.09 1.648.27v-3.386c-.449-.09-.869-.12-1.288-.12-1.709 0-3.387 1.109-3.956 2.817v-2.667h-3.297v14.983zM157.908 51.265h3.143v-7.252l7.316 7.252h4.225l-8.342-8.3 6.844-6.683h-4.166l-5.877 5.694V26h-3.143v25.265z"
        />
        <path
          fill={color || "#fff"}
          d="M171.682 43.774c0 4.285 3.117 7.791 7.222 7.791 2.158 0 3.956-1.109 4.795-2.307v2.007h3.296V36.282h-3.296v2.008c-.839-1.2-2.637-2.308-4.795-2.308-4.105 0-7.222 3.506-7.222 7.792zm12.287 0c0 2.697-1.799 4.794-4.466 4.794-2.667 0-4.465-2.097-4.465-4.794 0-2.697 1.798-4.795 4.465-4.795s4.466 2.098 4.466 4.795zm6.893 7.491H194V26h-3.138v25.265z"
        />
      </g>
    )}
    <defs>
      <clipPath id="logoclippath">
        <path fill={color || "#fff"} d="M0 0h76v64.097H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;

Logo.propTypes = {
  /**
   * This is white by default.
   */
  color: PropTypes.string,
  /**
   * If this is true, "alserkal" doesn't appear
   */
  noText: PropTypes.bool,
};
