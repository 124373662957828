import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { useInView } from "react-intersection-observer";
import { Link } from "@reach/router";
import Logo from "./../Logo";
import GlobalContext from "./../GlobalContext";
import { CartContext } from "./../StoreWrapper/CartContext";
// import { Link } from "gatsby";
import { HeaderWrapper, HeaderDiv } from "./elements";
import Search from "./../icons/Search";
import Totebag from "./../icons/Totebag";
import Account from "./../icons/Account";
import MenuOpen from "./../icons/MenuOpen";

/**
This needs more specification:

- what does "search" do? Does that go to a search page or open a search field here?
- how different are things when you're logged in?
- is the store icon stateful in some way?

This needs icons! Right now we have substitutes in there.

To get this to work in Storybook with Gatsby running at the same time, `Link` is coming fom `@reach/router` and not `gatsby`

**/

// TODO: On scroll, the "alserkal" fades out and the wordmark becomes smaller and moves to the left.
// WATCH THIS and feed it in as "scrollPoint"

const Header = ({
  loggedIn,
  openStore,
  openUser,
  openSearch,
  openMenu,
  hideUser,
  hideSearch,
  darkMode,
  skipHeader,
  headerColor,
  headerBlack,
}) => {
  const [menuColor, setMenuColor] = React.useState(
    headerColor ? headerColor : darkMode ? "#eee" : "#111"
  );
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const location = useLocation();
  const { toggle } = React.useContext(CartContext);
  const { useStore, useStaging } = React.useContext(GlobalContext);
  React.useEffect(() => {
    // console.log("changing menu color!");
    setMenuColor(headerColor ? headerColor : inView ? "#eee" : "#eee"); // THIS IS CURRENTLY IGNORING DARKMODE!
  }, [inView]);
  // console.log(inView, menuColor);
  const realUseStore = useStore || useStaging;
  return (
    <HeaderWrapper skipHeader={skipHeader} headerBlack={headerBlack}>
      <HeaderDiv className={!inView ? "fixed" : ""}>
        <Link to={"/"}>
          <Logo color={"var(--black)"} alt="Alserkal" noText={!inView} />
        </Link>
        <ul>
          {/* {!inView || !realUseStore ? null : (
            <li>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  if (location.pathname === "/tote") {
                    toggle(true);
                  } else {
                    openStore(true);
                  }
                }}
              >
                <Totebag alt="Store" color={menuColor} />
              </a>
            </li>
          )}
          {!inView || !useStore || hideUser ? null : (
            <li>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  openUser(true);
                }}
              >
                <Account alt={loggedIn ? "User" : "Log in"} color={menuColor} />
              </a>
            </li>
          )} */}
          {!inView || hideSearch ? null : (
            <li>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  openSearch(true);
                }}
              >
                <Search alt="Search" color={"var(--black)"} />
              </a>
            </li>
          )}
          <li>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                openMenu(true);
              }}
            >
              <MenuOpen color={"var(--black)"} alt="Menu" />
            </a>
          </li>
        </ul>
      </HeaderDiv>
      <div ref={ref} />
    </HeaderWrapper>
  );
};

export default Header;

Header.propTypes = {
  /**
	Assuming that this is coming from somewhere else, though it could be set here?
	*/
  loggedIn: PropTypes.bool,
  /**
	These functions are fired if the button is clicked.
	*/
  openUser: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openStore: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openMenu: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openSearch: PropTypes.func,
  hideStore: PropTypes.bool,
  hideUser: PropTypes.bool,
  hideSearch: PropTypes.bool,
  darkMode: PropTypes.bool,
};
