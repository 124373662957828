import React from "react";
import PropTypes from "prop-types";
import GlobalContext from "./../GlobalContext";
import { Link } from "@reach/router";
import { FooterWrapper } from "./elements";
import Logo from "./../Logo";
import MiscSmallSocial from "./../icons/MiscSocialSmall";
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

// import SocialButtons from "../../assets/icons/misc-social-small.svg";
import UserIcon from "./../../assets/icons/icons-account.svg";

/**
- This expects two arrays of paired names and IDs. How often does this change?
- IDs will probably be turned into a link?
- If an ID has `emphasize` set to true, we could make it green (like in SlidingMenu)
**/

const turnOffCommunityLogin = true;

const Footer = ({ leftList, rightList, copyright, reportLink, color }) => {
  const { footerContent, footerCopyright } = React.useContext(GlobalContext);
  const theLeftList = footerContent
    ? footerContent.slice(0, Math.ceil(footerContent.length / 2)).map((x) => {
        return { id: x.footerItemLink, name: x.footerItemName };
      })
    : leftList;
  const theRightList = footerContent
    ? footerContent
        .slice(Math.ceil(footerContent.length / 2), footerContent.length)
        .map((x) => {
          return { id: x.footerItemLink, name: x.footerItemName };
        })
    : rightList;
  const thisYear = new Date();
  return (
    <FooterWrapper color={"var(--red)"}>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
	      cookieName="Alserkal"       
	      style={{background:"#2fed1c"}}
	      buttonStyle={{color:"red"}}
        enableDeclineButton
        declineButtonText="Decline"
      >
        <span class="cookie_txt">         
         <span class="txt_bold">Your privacy. </span> We use cookies to improve your experience on our site. To find out more, read our <span class='cookieunderline'> <a href="https://alserkal.online/privacy/">privacy policy</a> </span> 
        </span>
      </CookieConsent>
      <div>
        <div>
          <div>
            {theLeftList ? (
              <ul>
                {theLeftList.map((item, index) => (
                  <li key={index}>
                    <a
                      href={`/${item.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        reportLink(item.id);
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
                <li className="big">
                  {turnOffCommunityLogin ? (
                    <MiscSmallSocial useInstagram />
                  ) : (
                    <a
                      href={`/communiylogin`}
                      disabled
                      onClick={(e) => {
                        e.preventDefault();
                        reportLink("communitylogin");
                      }}
                    >
                      <img src={UserIcon} alt={"User"} />
                      <span> Community Login</span>
                    </a>
                  )}
                </li>
              </ul>
            ) : null}
          </div>
          <div>
            {theRightList ? (
              <ul>
                {theRightList.map((item, index) => (
                  <li key={index}>
                    {item.id == "newsletter"?(
                      <a
                      href="http://eepurl.com/hzEv61"
                                      
                    >
                      {item.name}
                    </a>) :(
                       <a
                       href={`/${item.id}`}
                       onClick={(e) => {
                         e.preventDefault();
                         reportLink(item.id);
                       }}
                     >
                       {item.name}
                     </a>
                    )
                    }
                  </li>
                ))}
                {theLeftList.length > theRightList.length ? <li /> : null}
                <li className={`big`}>
                  {turnOffCommunityLogin ? null : <MiscSmallSocial />}
                </li>
              </ul>
            ) : null}
          </div>
        </div>
        <div>
          <ul>
            <li>
              <span>
                © {thisYear.getFullYear()} {footerCopyright || copyright}
              </span>
              <Link to={"/"}>
                <Logo noText />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;

Footer.propTypes = {
  leftList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      emphasize: PropTypes.bool,
    })
  ),
  rightLight: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      emphasize: PropTypes.bool,
    })
  ),
  copyright: PropTypes.string,
  reportLink: PropTypes.func,
};
