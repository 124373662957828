import styled from "styled-components";

export const FooterWrapper = styled.div`
  --accentColor: ${(props) => props.color || "var(--green)"};
  background-color: var(--black);
  color: var(--white);
  display: flex;
  height: 396px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  & > div {
    display: flex;
    width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
    align-items: bottom;
    justify-content: space-between;
    & > div:first-of-type {
      flex: 2;
      margin-top: auto;
      max-width: 820px;
      align-items: bottom;
      & + div {
        flex: 1;
        margin-top: auto;
        max-width: 400px;
        margin-left: 20px;
        border-bottom: 1px solid var(--accentColor);
        & svg {
          position: relative;
          right: -100px;
          right: -52px;
        }
        & ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          & li {
            font-size: 12px;
            text-transform: lowercase;
            letter-spacing: 0.36px;
            display: inline-flex;
            align-items: flex-end;
            display: flex;
            & span {
              flex: 2;
              width: 100%;
              position: relative;
              white-space: nowrap;
              bottom: 9px;
            }
          }
        }
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        & > ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          & li {
            height: 40px;
            margin: 0 0 6.5px;
            font-size: 18px;
            font-weight: normal;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--accentColor);
            text-transform: lowercase;
            & a {
              display: inline-flex;
              align-items: center;
              transition: 0.25s;
              color: var(--white);
              padding-bottom: 6px;
              &:hover {
                color: var(--accentColor);
              }
              & img {
                margin-right: 8px;
              }
            }
            &.big {
              height: 50px;
              margin-bottom: 0;
            }
            &.addspace {
              margin-top: 53px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    height: initial;
    padding-top: 20px;
    & > div {
      flex-direction: column;
      width: initial;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      & > li {
        font-size: 16px;
      }
      & > div > div {
        & > ul li {
          width: 161px;
          height: 35px;
          margin-bottom: 6px;
          &.big {
            height: 45px;
          }
        }
      }
      & > div:first-of-type {
        max-width: unset;
        & div {
          width: initial;
          flex: 1;
          &:first-of-type {
            margin-right: 12px;
          }
        }
        & + div {
          margin-left: 0;
          max-width: 100%;
          & ul li {
            align-items: center;
            height: 100px;
            width: 334px;
            & span {
              white-space: initial;
              min-width: 200px;
              bottom: 0;
            }
            & a {
              position: relative;
              right: -4px;
            }
          }
        }
      }
    }
  }

  .CookieConsent{
    height: 37px;
    font-size: 14px;
    display: block !important;
    }
    .CookieConsent div:first-child{
      margin: 10px 15px !important;
      display: inline-block;
      width: 77%;
    }
    .CookieConsent div:last-child{
      display: inline-block;
      width: 20%;
      text-align: right;
    }
   
    .CookieConsent .txt_bold {
      font-weight: 700;
    }
    .CookieConsent .cookie_txt{
      margin: -4px;
    }
    .CookieConsent .cookie_txt .cookieunderline{
      text-decoration: underline;
    }
    .cookieunderline a{
      color:white;
    }
  
    .CookieConsent #rcc-decline-button{
      background: #2fed1c !important;
      border: 1px solid !important;
      margin: 5px !important;
      color: white !important;
    }
    .CookieConsent #rcc-confirm-button{
      background: #2fed1c !important;
      border: 1px solid !important;
      color:rgb(229, 229, 229) !important;
      margin: 5px !important;
      color: white !important;
    }
    @media only screen and (max-width:991px){
      .CookieConsent{
            height: auto;
            text-align: center;
      }
       .CookieConsent div:last-child {
        width: 100%;
        text-align: center;
      }
    }
`;
