import styled from "styled-components";

export const HeaderDiv = styled.header`
  /**
* hasScrolled is true if the user has scrolled down 
 */
  display: flex;
  width: 100%;
  height: var(--headerHeight);
  align-items: center;
  padding: 0 20px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s;
  pointer-events: none;
  & > a > svg {
    transition: 0.5s;
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8;
    max-width: 100%;
    padding-left: 13px;
    padding-right: 30px;
    & > a {
      position: relative;
      & > svg {
        transform: scale(0.72) translateX(-20px);
      }
    }
  }
  & > ul {
    display: inline-flex;
    list-style-type: none;
    margin-left: auto;
    & > li {
      display: inline-block;
      margin-left: 50px;
    }
  }
  & a {
    pointer-events: initial;
  }
  @media (max-width: 767px) {
    & > ul > li {
      display: none;
      &:last-of-type {
        display: inline-block;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  --headerHeight: 110px;
  position:relative;
  background-color:#ffffff;
  z-index: 9;
  left: 0;
  right: 0;
  @media (max-width: 1500px) {
    width:90%;
    max-width:1280px;
    margin:0 auto;
  }
  & > header + div {
    position: relative;
    top: var(--headerHeight);
  }
`;
