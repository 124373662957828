import styled from "styled-components";

export const ToteDiv = styled.div`
  position: relative;
  & > span {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--white);
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: var(--black);
    border-radius: 100%;
    font-weight: bold;
  }
`;
